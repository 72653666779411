html {
  /* height: 100%; */
}
body {
  margin: 0;
  /* background-color: #f0f0f7; */
  height: 100%;
  font-size: 12px;
  /* letter-spacing: 1px; */
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "M PLUS Rounded 1c",
    "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: fadeIn 2s ease 0s 1 normal;
  animation: fadeIn 2s ease 0s 1 normal;
}
h2 {
  color: midnightblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.1;
    top: 20px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

/* サイドに出てくる詳細のアニメーション */
.SideDetail {
  animation: SideDetailAnimation 0.3s ease 0s 1 normal;
}

/* CSSアニメーションの設定 */
@keyframes SideDetailAnimation {
  0% {
    opacity: 1; /*初期状態では透明に*/
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes SideDetailOutAnimation {
  0% {
    opacity: 1; /*初期状態では透明に*/
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}

/* ローディング画面 */
#loading {
  width: 100vw;
  height: 100vh;
  transition: all 1s;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.spinner {
  width: 100px;
  height: 100px;
  margin: 200px auto;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: calc(100vh / 2 - 50px) auto auto auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

/* ローディングアニメーション */
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#confetti {
  background: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.3;
  z-index: 1;
  top: 0;
  left: 0;
}

.MuiOutlinedInput-input {
  padding: 1px;
}

.ReactModal__Overlay {
  z-index: 3;
}

.MuiMenuItem-root {
  font-size: 12px;
  min-height: 40px;
}

/* ReadOnly対応 */
/* input:read-only,
textarea:read-only {
  background-color: rgb(234, 233, 233);
}
input:read-only:hover,
textarea:read-only:hover {
  background-color: rgb(234, 233, 233);
}
input:read-only:focus,
textarea:read-only:focus {
  background-color: rgb(234, 233, 233);
} */
/* ReadOnly対応 */
